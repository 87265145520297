<template>
	<div id="wrap">
		<top page="offline" @search="handleSearch" :showSearchButton="true" />
		<div class="mobile-wrap">
			<focus-pic category="首页焦点图" />
			<!-- 活动报名 activityList数组不为0时进行渲染 -->
			<div class="module-1" v-if="activityList.length > 0">
				<div class="mainBox wow fadeInUp" data-wow-offset="0" data-wow-delay="0.1s">
					<div class="module-title">
						<h2>活动报名 <span>ACTIVITY</span></h2>
					</div>
				</div>
				<div class="mainBox">
					<div class="list-box">
						<div 
							class="list wow fadeInUp" 
							data-wow-offset="0" 
							data-wow-delay="0.1s"
							v-for="item in activityList"
							:key="item.id"
						>
							<div class="label" v-if="item.condition.indexOf('VIP') > -1">VIP活动</div>
							<div class="pic">
								<img :src="item.cover.absolutePath"  />
							</div>
							<div class="con">
								<h1>{{item.name}}</h1>
								<div class="list-info">
									<div class="time">{{item.activityTime}}</div>
									<div class="adds">{{item.location}}</div>
									<div class="price">¥{{item.price}}</div>
								</div>
								<div class="btn-box">
									<button class="btn" @click="handleShowActivityDetail(item.id)">报名参与</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- module-1 -->
			<div class="module-2" id="divSnowflake">
				<div class="mainBox wow fadeInUp" data-wow-offset="0" data-wow-delay="0.1s">
					<div class="module-title">
						<h2>雪花系<span>C-FLORAL STUDENTS</span></h2>
					</div>
				</div>
				<div class="mainBox">
					<div class="list-box wow fadeInUp" data-wow-offset="0" data-wow-delay="0.1s">
						<div 
							class="list"
							v-for="item in snowflakeList"
							:key="item.id"
							@click="handleShowSnowflakeWindow(item)"
						>
							<div class="pic">
								<img :src="item.thumbnail.absolutePath" />
							</div>
							<div class="user">
								<img :src="item.headImage.absolutePath" />
								<h1>{{item.title}}</h1>
								<span>{{item.subTitle}}</span>
							</div>
							<div class="con" v-html="item.content"></div>
						</div>
						<!--  -->
						<div class="clear"></div>
					</div>
				</div>
				<div class="loadMore" v-if="paging.pageIndex < paging.pageCount">
					<a @click="handleLoadMoreSnowflake">+加载更多+</a>
				</div>
			</div>
			<!-- module-2 -->
		</div>
		<bottom />
		<div class="works-window" style="display: none;">
			<div class="window-con">
				<div class="windos-close" @click="handleCloseSnowflakeWindow"><span class="iconfont iconclose"></span></div>
				<div class="list">
					<div class="pic">
						<img :src="selectedSnowflake.thumbnail.absolutePath" />
					</div>
					<div class="user">
						<img :src="selectedSnowflake.headImage.absolutePath" />
						<h1>{{selectedSnowflake.title}}</h1>
						<span>{{selectedSnowflake.subTitle}}</span>
					</div>
					<div class="con" v-html="selectedSnowflake.content"></div>
				</div>
			</div>
		</div>
		<side />
	</div>
</template>

<script>
import top from "./components/top.vue"
import bottom from "./components/bottom.vue"
import focusPic from "./components/focusPic.vue"
import side from "./components/side.vue"
import { wxShare } from "@/utils/share.js"
export default {
	page: {
		title: '线下活动'
	},
	data() {
		return {
			activityList: [],
			snowflakeList: [],
			selectedSnowflake: {
				title: '',
				subTitle: '',
				thumbnail: {
					absolutePath: ''
				},
				headImage: {
					absolutePath: ''
				},
				content: ''
			},
			paging: {
				pageIndex: 1,
				pageSize: 50,
				recordCount: 0,
				pageCount: 0 
			},
			search: {
				title: ''
			}
		}
	},
	components: {
		top,
		bottom,
		focusPic,
		side
	},
	methods: {
		handleWxShare() {
			let url = window.location.href

			this.$api.wechat.mpwechat.getJsapiSignature(url)
			.then(response => {
				if(response.code === '0') {

					let browser = window.navigator.userAgent.toLowerCase();	

					// 非微信浏览器不执行分享代码
					if (browser.match(/MicroMessenger/i) != 'micromessenger') {
						return;
					} 	

					//微信加签
                    let obj = {
                        appId: response.data.appId,
                        nonceStr: response.data.nonceStr,
                        signature: response.data.signature,
                        timestamp: response.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };


                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: 'C-FLROAL线下活动报名', // 分享标题
                        desc: '官方指定报名渠道',
                        link: response.data.url,
                        imgUrl: 'http://www.c-floral.net/images/share/logo-05.jpg' // 分享图标
                    };

					//引用
                    wxShare(obj, shareData);
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		goAnchor (selector) {
			document.querySelector(selector).scrollIntoView({
				behavior:"smooth"
			})
		},
		handleFindActivity() {
			this.$api.home.activity.findAll()
			.then(response => {
				if(response.code === '0') {
					this.activityList = response.data
					this.$nextTick(() => {
						new this.$wow.WOW().init()
					})
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleFindSnowflake() {
			this.$api.home.snowflake.findAll(this.search.title, this.paging.pageIndex, this.paging.pageSize)
			.then(response => {
				if(response.code === '0') {
					this.snowflakeList = response.data.snowflakeList
					this.paging = response.data.paging
					this.$nextTick(() => {
						new this.$wow.WOW().init()
					})
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleLoadMoreSnowflake() {
			this.$api.home.snowflake.findAll(this.search.title ,this.paging.pageIndex + 1, this.paging.pageSize)
			.then(response => {
				if(response.code === '0') {
					this.snowflakeList = this.snowflakeList.concat(response.data.snowflakeList)
					this.paging = response.data.paging
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleShowActivityDetail(id) {
			this.$router.push({ 'path': `/activity/${id}` })
		},
		handleShowSnowflakeWindow(snowflake) {
			this.selectedSnowflake = {
				title: '',
				subTitle: '',
				thumbnail: {
					absolutePath: ''
				},
				headImage: {
					absolutePath: ''
				},
				content: ''
			}
			this.selectedSnowflake = snowflake
			$('.works-window').fadeToggle('');
		},
		handleCloseSnowflakeWindow() {
			$('.works-window').fadeOut('');
		},
		handleSearch(keywords) {
			this.goAnchor('#divSnowflake')
			this.search.title = keywords
			this.handleFindSnowflake()
		}
	},
	mounted() {
		this.handleWxShare()
		this.handleFindActivity()
		this.handleFindSnowflake()
	}
}
</script>

<style src="../style/css/default.css" scoped></style>
<style scoped>

</style>